<template>
  <vue-scroll>
    <v-container fluid>
      <v-card class="ma-4 pa-12" elevation="20">
        <h1>{{ $t("account.title.myAccount") }}</h1>
        <v-container fluid class="pa-0">
          <v-form ref="accountForm" v-model="validAccount">
            <v-row>
              <v-col :cols="breakPoint">
                <v-list>
                  <v-list-item-title>{{
                    $t("account.title.personalInfo")
                  }}</v-list-item-title>
                  <v-divider></v-divider>
                  <v-container fluid class="pa-0 mt-8">
                    <v-text-field
                      v-model="user.firstname"
                      filled
                      :label="$t('account.fields.firstname')"
                      :rules="ValidationRules.title"
                    ></v-text-field>
                    <v-text-field
                      v-model="user.lastname"
                      filled
                      :label="$t('account.fields.lastname')"
                      :rules="ValidationRules.title"
                    ></v-text-field>
                    <v-text-field
                      v-model="user.emailAddress"
                      filled
                      :label="$t('account.fields.mailAddress')"
                      :rules="ValidationRules.email"
                    ></v-text-field>
                    <v-text-field
                      v-model="user.phoneNumber"
                      filled
                      :label="$t('account.fields.fields.phoneNumber')"
                      :rules="ValidationRules.phoneNumber"
                    ></v-text-field>
                  </v-container>
                </v-list>

                <v-list>
                  <v-list-item-title>{{
                    $t("account.title.photo")
                  }}</v-list-item-title>
                  <v-divider></v-divider>
                  <v-container
                    fluid
                    class="
                      pa-0
                      mt-8
                      d-flex
                      flex-row
                      justify-center
                      align-center
                      photo-section
                    "
                  >
                    <img
                      v-if="actualPhoto && !newPhotoPreview"
                      :src="actualPhoto"
                      width="64"
                      class="d-inline-block"
                      alt="Current photo preview"
                    />
                    <img
                      v-if="newPhotoPreview"
                      :src="newPhotoPreview"
                      width="64"
                      class="d-inline-block"
                      alt="New photo preview"
                    />
                    <p v-if="!actualPhoto && !newPhotoPreview">
                      {{ $t("account.fields.noPhoto") }}
                    </p>

                    <v-file-input
                      @change="changePhoto"
                      accept="image/*"
                      :rules="ValidationRules.photo"
                      @click:clear="newPhotoPreview = null"
                      :label="$t('account.fields.searchFiles')"
                    ></v-file-input>
                    <v-btn
                      v-if="actualPhoto"
                      color="error"
                      class="ml-4"
                      :loading="deletingPhoto"
                      @click="deletePhoto"
                    >
                      {{ $t("actions.delete") }}
                    </v-btn>
                  </v-container>
                </v-list>

                <h1>{{ $t("account.title.preferences") }}</h1>

                <v-list-item-title>{{
                  $t("account.title.accessibility")
                }}</v-list-item-title>
                <v-divider></v-divider>
                <v-list>
                  <template v-if="isMasterBase()">
                    <v-list-item three-line>
                      <v-list-item-content>
                        <v-list-item-title>
                          {{ $t("account.title.sound") }}
                        </v-list-item-title>

                        <v-list-item-subtitle>
                          <span class="grey--text">
                            {{ $t("account.other.detailAccessibility") }}
                          </span>
                        </v-list-item-subtitle>
                      </v-list-item-content>

                      <v-list-item-action>
                        <v-switch
                          class="d-inline"
                          @change="savePreferences"
                          :input-value="preferences.soundOn"
                        ></v-switch>
                      </v-list-item-action>
                    </v-list-item>
                  </template>
                  <v-list-item three-line>
                    <v-list-item-content>
                      <v-list-item-title>
                        {{ $t("account.title.sendMail") }}
                      </v-list-item-title>

                      <v-list-item-subtitle>
                        <span class="grey--text">
                          {{ $t("account.other.sendMailDetails") }}
                        </span>
                      </v-list-item-subtitle>
                    </v-list-item-content>
                    <v-list-item-action>
                      <v-switch
                        @change="savePreferencesMail"
                        :input-value="preferences.sendMail"
                      ></v-switch>
                    </v-list-item-action>
                  </v-list-item>
                </v-list>

                <!--                  <v-list>-->
                <!--                    <v-list-item-title>{{ $t("account.title.communication") }}</v-list-item-title>-->
                <!--                    <v-divider></v-divider>-->
                <!--                    <v-container fluid class="pa-0 mt-8">-->

                <!--                      <v-switch class="float-right ml-2"></v-switch>-->
                <!--                      <v-list-item-subtitle>{{ $t('account.other.subscribeNewsletter')}}</v-list-item-subtitle>-->
                <!--                      <p class="grey&#45;&#45;text" v-html="$t('account.other.detailsNewsletter')"></p>-->

                <!--                      <v-switch class="float-right ml-2"></v-switch>-->
                <!--                      <v-list-item-subtitle>{{ $t('account.other.subscribeCommunication')}}</v-list-item-subtitle>-->
                <!--                      <p class="grey&#45;&#45;text" v-html="$t('account.other.detailsCommunication')"></p>-->

                <!--                    </v-container>-->
                <!--                  </v-list>-->
              </v-col>
              <v-spacer></v-spacer>
              <v-col :cols="breakPoint">
                <v-list>
                  <v-list-item-title>{{
                    $t("account.title.security")
                  }}</v-list-item-title>
                  <v-divider></v-divider>

                  <template v-if="!editPassword">
                    <v-btn @click="editPassword = true" class="warning my-4"
                      >{{ $t("account.fields.changePassword") }}
                    </v-btn>
                  </template>
                  <template v-else>
                    <v-container fluid class="pa-0 mt-8">
                      <v-form ref="formPassword">
                        <v-text-field
                          :type="type1"
                          name="current-password"
                          filled
                          :label="$t('account.fields.actualPassword')"
                          :rules="ValidationRules.required"
                          v-model="currentPassword"
                        >
                          <template v-slot:append>
                            <v-btn
                              icon
                              @mousedown="type1 = 'text'"
                              @mouseup="type1 = 'password'"
                            >
                              <v-icon>mdi-eye</v-icon>
                            </v-btn>
                          </template>
                        </v-text-field>
                        <v-text-field
                          :type="type2"
                          name="new-password"
                          filled
                          :label="$t('account.fields.newPassword')"
                          :rules="ValidationRules.password"
                          v-model="newPassword"
                        >
                          <template v-slot:append>
                            <v-btn
                              icon
                              @mousedown="type2 = 'text'"
                              @mouseup="type2 = 'password'"
                            >
                              <v-icon>mdi-eye</v-icon>
                            </v-btn>
                          </template>
                        </v-text-field>
                        <v-text-field
                          :type="type3"
                          name="new-password"
                          filled
                          :label="$t('account.fields.confirmPassword')"
                          v-model="confirmNewPassword"
                          :error-messages="isSameAsNewPassword"
                        >
                          <template v-slot:append>
                            <v-btn
                              icon
                              @mousedown="type3 = 'text'"
                              @mouseup="type3 = 'password'"
                            >
                              <v-icon>mdi-eye</v-icon>
                            </v-btn>
                          </template>
                        </v-text-field>
                        <v-btn color="success" @click="savePassword">{{
                          $t("actions.save")
                        }}</v-btn>
                        <v-btn
                          @click="editPassword = false"
                          class="my-4"
                          text
                          >{{ $t("actions.cancel") }}</v-btn
                        >
                      </v-form>
                    </v-container>
                  </template>

                  <v-list-item-title>{{
                    $t("account.title.other")
                  }}</v-list-item-title>
                  <v-divider></v-divider>
                  <v-container fluid class="pa-0 mt-8">
                    <v-container
                      fluid
                      class="
                        pa-0
                        mt-8
                        d-flex
                        flex-row flex-wrap
                        justify-center
                        align-center
                        photo-section
                      "
                    >
                      <h3>{{ $t("account.title.signature") }}</h3>
                      <v-spacer></v-spacer>
                      <div class="w-100"></div>
                      <img
                        v-if="actualSignature && !newSignaturePreview"
                        :src="actualSignature"
                        width="64"
                        class="d-inline-block"
                        alt="Current signature photo preview"
                      />
                      <img
                        v-if="newSignaturePreview"
                        :src="newSignaturePreview"
                        width="64"
                        class="d-inline-block"
                        alt="New signature photo preview"
                      />
                      <p v-if="!actualSignature && !newSignaturePreview">
                        {{ $t("account.fields.noSignature") }}
                      </p>
                      <v-file-input
                        @change="changeSignature"
                        accept="image/*"
                        :rules="ValidationRules.signature"
                        ref="fileSignature"
                        :value="signature"
                        @click:clear="newSignaturePreview = null"
                        :label="$t('account.fields.searchFiles')"
                      ></v-file-input>
                      <br />
                      <v-btn
                        v-if="actualSignature"
                        :loading="deletingSignature"
                        color="error ml-4"
                        @click="deleteSignature"
                        >{{ $t("actions.delete") }}
                      </v-btn>
                      <div class="w-100"></div>

                      <template v-if="!editPasswordSignature">
                        <v-container fluid class="align-start pa-0">
                          <v-btn
                            @click="editPasswordSignature = true"
                            class="warning my-4"
                          >
                            {{ $t("account.fields.changePasswordSignature") }}
                          </v-btn>
                        </v-container>
                      </template>
                      <template v-else>
                        <v-container fluid class="pa-0 mt-8">
                          <v-form ref="formPasswordSignature">
                            <v-text-field
                              :type="type4"
                              name="new-password"
                              filled
                              :label="$t('account.fields.newPassword')"
                              :rules="ValidationRules.password"
                              v-model="newPasswordSignature"
                            >
                              <template v-slot:append>
                                <v-btn
                                  icon
                                  @mousedown="type4 = 'text'"
                                  @mouseup="type4 = 'password'"
                                >
                                  <v-icon>mdi-eye</v-icon>
                                </v-btn>
                              </template>
                            </v-text-field>
                            <v-text-field
                              :type="type5"
                              name="new-password"
                              filled
                              :label="$t('account.fields.confirmPassword')"
                              :rules="[samePassword]"
                              v-model="confirmNewPasswordSignature"
                            >
                              <template v-slot:append>
                                <v-btn
                                  icon
                                  @mousedown="type5 = 'text'"
                                  @mouseup="type5 = 'password'"
                                >
                                  <v-icon>mdi-eye</v-icon>
                                </v-btn>
                              </template>
                            </v-text-field>
                            <v-btn
                              color="success"
                              @click="savePasswordSignature"
                              >{{ $t("actions.save") }}</v-btn
                            >
                            <v-btn
                              @click="editPasswordSignature = false"
                              class="my-4"
                              text
                              >{{ $t("actions.cancel") }}
                            </v-btn>
                          </v-form>
                        </v-container>
                      </template>
                    </v-container>
                  </v-container>
                </v-list>
              </v-col>
            </v-row>

            <v-row>
              <v-spacer></v-spacer>
              <v-btn
                color="primary"
                @click="update"
                :disabled="loadingSubmit"
                :loading="loadingSubmit"
              >
                {{ $t("actions.save") }}
              </v-btn>
              <v-spacer></v-spacer>
            </v-row>
          </v-form>
        </v-container>
      </v-card>
    </v-container>
  </vue-scroll>
</template>

<script>
import MixinValidation from "@/Mixins/ValidationRules";
import PlaneteOnlineService from "@/Services/PlaneteOnlineService";
import UserService from "@/Services/UserService";
import Images from "@/Utils/Images";
import CryptoJS from "crypto-js";
import Vuex from "vuex";

export default {
  name: "MyAccount",
  mixins: [MixinValidation],
  computed: {
    ...Vuex.mapState(["informations", "preferences"]),
    breakPoint() {
      const { lg, sm } = this.$vuetify.breakpoint;
      return lg ? 5 : sm ? 12 : 5;
    },
    actualPhoto() {
      return this.informations.photo;
    },
    actualSignature() {
      return this.informations.signature;
    },
    originalUser() {
      return this.informations;
    },
    isSameAsNewPassword() {
      return this.newPassword == this.confirmNewPassword
        ? ""
        : this.$t("account.fields.passwordDifferent");
    },
  },
  data() {
    return {
      user: Object.assign({}, this.originalUser),
      editPassword: false,
      editPasswordSignature: false,
      loadingSubmit: false,
      currentPassword: "",
      currentPasswordSignature: "",
      newPassword: "",
      newPasswordSignature: "",
      confirmNewPassword: "",
      confirmNewPasswordSignature: "",
      signature: null,
      newSignaturePreview: "",
      newPhotoPreview: "",
      photo: null,
      newPhoto: null,
      signatureChanged: false,
      photoChanged: false,
      validAccount: false,
      type1: "password",
      type2: "password",
      type3: "password",
      type4: "password",
      type5: "password",
      deletingSignature: false,
      deletingPhoto: false,
      // phoneNumber: "",
    };
  },
  methods: {
    samePassword() {
      return (
        (this.confirmNewPasswordSignature &&
          this.confirmNewPasswordSignature == this.newPasswordSignature) ||
        this.$t("validation.passwordDifferent")
      );
    },
    savePreferences() {
      let timeout = 0;
      if (this.preferences.soundOn) {
        this.$uiSounds.play("switchOn");
      } else {
        this.$uiSounds.play("switchOff");
        timeout = 50;
      }

      setTimeout(async () => {
        this.$uiSounds.state.preferences.soundOn = !this.preferences.soundOn;
        this.$store.commit("toggleSound", !this.preferences.soundOn);

        try {
          let store = this.$_.pick(this.$store.state, ["preferences"]);
          await PlaneteOnlineService.postInterface(store);
        } catch (e) {
          console.error(e);
        }
      }, timeout);
    },
    savePreferencesMail() {
      this.$uiSounds.state.preferences.sendMail = !this.preferences.sendMail;
      this.$store.commit("toggleSendMail", !this.preferences.sendMail);

      setTimeout(async () => {
        try {
          let store = this.$_.pick(this.$store.state, ["preferences"]);
          await PlaneteOnlineService.postInterface(store);
        } catch (e) {
          console.error(e);
        }
      }, 0);
    },
    async deletePhoto() {
      this.deletingPhoto = true;
      await this.sleep(1000);

      let response = await UserService.deletePhoto();

      if (response) {
        this.newPhotoPreview = null;
        this.photo = null;
        this.$store.commit("setUser", { photo: null });
        this.$nSuccess(this.$t("account.messages.photoDeleted"));
      } else {
        this.$nError(this.$t("account.messages.photoNotDeleted"));
      }
      this.deletingPhoto = false;
    },
    async changeSignature(val) {
      if (val) {
        let b64 = await Images.toBase64(val);
        this.newSignaturePreview = b64;
        this.signature = val;
        this.signatureChanged = true;
      }
    },
    async changePhoto(val) {
      if (val) {
        let b64 = await Images.toBase64(val);
        this.newPhotoPreview = b64;
        this.user.photo = val;
        this.photoChanged = true;
      }
    },
    async deleteSignature() {
      this.deletingSignature = true;
      let response = await UserService.deleteSignature();
      if (response) {
        this.signature = null;
        this.newSignaturePreview = null;
        this.$store.commit("setUser", { signature: null });
        this.$nSuccess(this.$t("account.messages.signatureDeleted"));
      } else {
        this.$nError(this.$t("account.messages.signatureNotDeleted"));
      }

      this.deletingSignature = false;
    },
    savePassword: async function () {
      if (this.$refs.formPassword.validate()) {
        if (this.newPassword == this.confirmNewPassword) {
          let response = await UserService.postUser({
            password: this.newPassword.toString(),
          });
          if (response) {
            if (response == "changingPasswordNeedAnotherPassword") {
              this.$nError(this.$t("account.messages.passwordIdentical"));
            } else {
              this.$nSuccess(this.$t("account.messages.passwordUpdated"));
            }
          } else {
            this.$nError(this.$t("account.messages.passwordNotUpdated"));
          }
        }
      }
    },
    savePasswordSignature: async function () {
      if (this.$refs.formPasswordSignature.validate()) {
        if (this.newPasswordSignature == this.confirmNewPasswordSignature) {
          let response = await UserService.postPasswordSignature({
            password: this.newPasswordSignature,
          });
          if (response) {
            this.$nSuccess(this.$t("account.messages.passwordChanged"));
          } else {
            this.$nError(this.$t("account.messages.passwordNotChanged"));
          }
        }
      }
    },
    update: async function () {
      if (this.$refs.accountForm.validate()) {
        this.loadingSubmit = true;

        let post = this.$_.pick(this.user, [
          "firstname",
          "lastname",
          "emailAddress",
          "phoneNumber",
        ]);
        post["email-address"] = post.emailAddress;

        let response = await UserService.postUser(post);

        if (response && !response.error) {
          this.$store.commit("setUser", { user: post });

          if (this.signatureChanged) {
            let formData = new FormData();
            formData.append("file", this.signature);
            
            let responseSignature = await UserService.postSignature(formData);
            if (responseSignature) {
              this.$store.commit("setUser", {
                signature: this.newSignaturePreview,
              });
              this.user = this.originalUser;
              this.signature = null;
              this.newSignaturePreview = null;
              this.signatureChanged = false;
            }
          }

          if (this.photoChanged) {
            let formDataPhoto = new FormData();
            formDataPhoto.append("file", this.user.photo);
            let responsePhoto = await UserService.postPhoto(formDataPhoto);
            if (responsePhoto == "OK") {
              let b64 = await Images.toBase64(this.user.photo);
              this.$store.commit("setUser", { photo: b64 });
            }
          }

          this.$nSuccess(this.$t("account.messages.profileUpdated"));
        } else {
          this.$nError(
            this.$t("account.messages.profileNotUpdated"),
            response.error ? response.error : ""
          );
        }
        this.loadingSubmit = false;
      }
    },
  },
  async mounted() {
    this.user = Object.assign({}, this.originalUser);

    let responseSignature = await UserService.getSignature();
    if (responseSignature) {
      this.$store.commit("setUser", { signature: responseSignature });
    }

    let responsePhoto = await UserService.getPhoto();
    if (responsePhoto) {
      this.$store.commit("setUser", { photo: responsePhoto });
    }
  },
};
</script>

<style scoped>
.w-100 {
  width: 100%;
}

.photo-section > .v-image {
  flex-grow: 0;
  flex-basis: 128px;
}
</style>